export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';

export const USER_PERMISSION = 'USER_PERMISSION';
export const USER_PERMISSION_SUCCESS = 'USER_PERMISSION_SUCCESS';
export const USER_PERMISSION_ERROR = 'USER_PERMISSION_ERROR';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';

// sedhal added
export const SUBDOMAINEXISTENCE = 'SUBDOMAINEXISTENCE';
export const SUBDOMAINEXISTENCE_SUCCESS = 'SUBDOMAINEXISTENCE_SUCCESS';
export const SUBDOMAINEXISTENCE_ERROR = 'SUBDOMAINEXISTENCE_ERROR';
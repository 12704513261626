import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  getReferralError,
  getReferralSuccess,
  patchReferralError,
  patchReferralSuccess,
} from './actions.referral';
import {
  GET_REFERRAL_AMOUNT,
  PATCH_REFERRAL_VALUE,
} from './constants.referral';
import { getReferral, patchReferralHttp } from './httpCalls.referral';

export function* GetReferralAmount(action) {
  const { cb } = action.payload;
  const res = yield call(getReferral, action.payload.params);
  if (res.success) {
    cb(res);
    yield put(getReferralSuccess(res));
  } else yield put(getReferralError(res));
}
export function* PatchReferralValue(action) {
  const { data, referralId, cb } = action.payload;
  const res = yield call(patchReferralHttp, data, referralId);
  cb(res);
  if (res.success) yield put(patchReferralSuccess(res));
  else yield put(patchReferralError(res));
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_REFERRAL_AMOUNT, GetReferralAmount),
    yield takeEvery(PATCH_REFERRAL_VALUE, PatchReferralValue),
  ]);
}

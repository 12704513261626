/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

import axiosInstance from 'helpers/axiosInstance';

export async function getAuctions(params) {
  const { auction_house_id } = params;
  delete params.auction_house_id;
  try {
    const res = await axiosInstance.get(
      `/auction-house/auction-list/${auction_house_id}`,
      {
        params,
      }
    );
    return res;
  } catch (err) {
    return err;
  }
}

export async function addEthToWallet(params) {
  const { wallet_address } = params;
  try {
    const res = await axiosInstance.post(
      '/auction-house/processRequest/v2',
      {
        wallet_address,
      },
      { timeout: 120000 }
    );
    return res;
  } catch (err) {
    return err;
  }
}

export async function addAuction(data) {
  try {
    const res = await axiosInstance.post('/auction', data);
    return res;
  } catch (err) {
    return err;
  }
}

export async function deleteAuction(auctionId) {
  try {
    const res = await axiosInstance.delete(`/auction`, {
      data: { ids: auctionId },
    });
    return res;
  } catch (err) {
    return err;
  }
}

export async function updateAuction(pathParam, data) {
  try {
    const res = await axiosInstance.put(`/auction/${pathParam}`, data);
    return res;
  } catch (err) {
    return err;
  }
}

export async function getAuction(auctionID) {
  try {
    const res = await axiosInstance.get(`/auction/${auctionID}`);
    return res;
  } catch (err) {
    return err;
  }
}

export async function getAuctionCategory() {
  try {
    const res = await axiosInstance.get('/auctions/category/');
    return res;
  } catch (err) {
    return err;
  }
}

export async function uploadAuctionImage(data) {
  try {
    const res = await axiosInstance.post('/uploads/', data, {
      headers: {
        'content-type': 'multipart/form-data',
        boundary: 'data.name',
      },
      params: { moduleName: 'auctions' },
    });
    return res;
  } catch (err) {
    return err;
  }
}

export async function uploadAuctionVideo(data) {
  try {
    const res = await axiosInstance.post('/uploads/', data, {
      headers: {
        'content-type': 'multipart/form-data',
        boundary: 'data.name',
      },
      params: { moduleName: 'auctions' },
    });
    return res;
  } catch (err) {
    return err;
  }
}

export async function getAuctionType() {
  try {
    const res = await axiosInstance.get(`/auction-category`);
    return res;
  } catch (err) {
    return err;
  }
}

export async function addAuctionType(data) {
  try {
    const res = await axiosInstance.post(`/auction-category/`, data);
    return res;
  } catch (err) {
    return err;
  }
}

export async function deleteAuctionType(data) {
  try {
    const res = await axiosInstance.delete(`/auction-category/`, { data });
    return res;
  } catch (err) {
    return err;
  }
}

export async function addAuctionStart(data) {
  try {
    const res = await axiosInstance.post(`/auction/start`, data);
    return res;
  } catch (err) {
    return err;
  }
}

export async function cancelAuction(auctionId) {
  try {
    const res = await axiosInstance.patch(`/auction/cancel/${auctionId}`);
    return res;
  } catch (err) {
    return err;
  }
}

import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import productSaga from './product/saga.product';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import userSaga from './user/sagas';
import authenticationSaga from './authentication/saga.authentication';
import auctionSaga from './auction/saga.auction';
import termsAndConditionSaga from './termsAndCondition/saga.termsAndCondition';
import ReferralSaga from './referral/saga.referral';
import AnalyticsSaga from './analytics/saga.analytics';
import GlobalAnalyticsSaga from './globalAnalytics/saga.globalAnalytics';
import CurrencySaga from './currency/saga.currency';
import UserBlockSaga from './user/userBlock/sagas.userBlock';

export default function* rootSaga() {
  yield all([
    authSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    userSaga(),
    productSaga(),
    authenticationSaga(),
    auctionSaga(),
    termsAndConditionSaga(),
    ReferralSaga(),
    AnalyticsSaga(),
    GlobalAnalyticsSaga(),
    CurrencySaga(),
    UserBlockSaga(),
  ]);
}

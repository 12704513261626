// import { setCurrentUser } from "helpers/Utils";
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  forgetpasswordError,
  forgetpasswordSuccess,
  loginError,
  loginSuccess,
  logoutError,
  logoutSuccess,
  registerError,
  registerSuccess,
  resetPasswordError,
  resetpasswordSuccess,
  subdomainExistenceError,
  // sedhal added,
  subdomainExistenceSuccess,
  updatePasswordError,
  updatePasswordSuccess,
  userPermissionError,
  userPermissionSuccess,
} from './actions.authentication';
import {
  CLEAR_AUTH_STATE,
  FORGET_PASSWORD,
  LOGIN,
  LOGOUT,
  REGISTER,
  RESET_PASSWORD,
  // sedhal added
  SUBDOMAINEXISTENCE,
  UPDATE_PASSWORD,
  USER_PERMISSION,
} from './constants.authentication';
import {
  existSubdomainRegister,
  forgetpassword,
  login,
  logout,
  registration,
  resetPasswordCalls,
  updatePassword,
  userPermission,
} from './httpCalls.authentication';

export function* Login(action) {
  const { params, cb } = action.payload;
  const res = yield call(login, params);
  cb(res);
  if (res.success) {
    yield put(loginSuccess(res));
  } else yield put(loginError(res));
}

export function* Register(action) {
  const { formData, cb } = action.payload;
  const res = yield call(registration, formData);
  cb(res);
  if (res.success) {
    yield put(registerSuccess(res));
  } else yield put(registerError(res));
}

// sedhal added
export function* subdomainExist(action) {
  const { formData, cb } = action.payload;
  const res = yield call(existSubdomainRegister, formData);
  cb(res);
  if (res.success) {
    yield put(subdomainExistenceSuccess(res));
  } else yield put(subdomainExistenceError(res));
}

export function* Forgetpassword(action) {
  const res = yield call(forgetpassword, action.payload);
  if (res?.success) {
    yield put(forgetpasswordSuccess(res));
    action.payload.cb();
  } else yield put(forgetpasswordError(res));
}

export function* ResetPassword(action) {
  const res = yield call(resetPasswordCalls, action.payload);
  if (res.success) {
    yield put(resetpasswordSuccess(res));
  } else yield put(resetPasswordError(res));
}

export function* Logout(action) {
  const { payload: cb } = action;
  const res = yield call(logout);
  cb(res);
  if (res?.success) {
    yield put(logoutSuccess(res));
  } else yield put(logoutError(res));
}

export function* UserPermission() {
  const res = yield call(userPermission);
  if (res.success) yield put(userPermissionSuccess(res));
  else yield put(userPermissionError(res));
}
export function* updatePasswordSaga(action) {
  try {
    const res = yield call(updatePassword, action.payload);
    if (res.success) {
      yield put(updatePasswordSuccess(res));
      action.payload.cb();
    } else yield put(updatePasswordError(res));
  } catch (err) {
    yield put(updatePasswordError(err));
  }
}

export function* clearAuthStateSaga() {
  yield put({ type: CLEAR_AUTH_STATE });
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(LOGIN, Login)],
    [yield takeEvery(REGISTER, Register)],
    [yield takeEvery(SUBDOMAINEXISTENCE, subdomainExist)],
    [yield takeEvery(FORGET_PASSWORD, Forgetpassword)],
    [yield takeEvery(LOGOUT, Logout)],
    [yield takeEvery(USER_PERMISSION, UserPermission)],
    [yield takeEvery(UPDATE_PASSWORD, updatePasswordSaga)][
      yield takeEvery(RESET_PASSWORD, ResetPassword)
    ],
    [yield takeEvery(CLEAR_AUTH_STATE, clearAuthStateSaga)]
  );
}

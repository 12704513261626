import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  getAnalyticsDetails,
  getAnalyticsListHttp,
} from './httpCalls.analytics';
import {
  getAnalyticsDetailsError,
  getAnalyticsDetailsSuccess,
  getAnalyticsListError,
  getAnalyticsListSuccess,
} from './actions.analytics';
import {
  GET_ANALYTICS_DETAILS,
  GET_ANALYTICS_LIST,
} from './constants.analytics';

export function* GetAnalyticsList(action) {
  const res = yield call(getAnalyticsListHttp, action.payload);
  if (res.success) yield put(getAnalyticsListSuccess(res));
  else yield put(getAnalyticsListError(res.message));
}

export function* GetAnalyticsDetailSaga(action) {
  const res = yield call(getAnalyticsDetails, action.payload);
  if (res?.success) yield put(getAnalyticsDetailsSuccess(res));
  yield put(getAnalyticsDetailsError());
  action.payload?.cb?.(res);
}

export default function* rootSaga() {
  yield all(
    [yield takeEvery(GET_ANALYTICS_LIST, GetAnalyticsList)],
    [yield takeEvery(GET_ANALYTICS_DETAILS, GetAnalyticsDetailSaga)]
  );
}

import axiosInstance from 'helpers/axiosInstance';

export async function getAnalyticsListHttp(params) {
  try {
    const res = await axiosInstance.get(`/auction/stats/list`, { params });
    return res;
  } catch (err) {
    return err;
  }
}

export async function getAnalyticsDetails({ auctionId, params }) {
  try {
    const res = await axiosInstance.get(
      `/auction/auction-analytics/${auctionId}`,
      { params }
    );
    return res;
  } catch (err) {
    return err;
  }
}

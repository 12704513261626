import axiosInstance from 'helpers/axiosInstance';

export async function getGlobalAnalyticsHttp(params) {
  try {
    const res = axiosInstance.get('auction-house/total-auction/list', {
      params,
    });
    return res;
  } catch (err) {
    return err;
  }
}

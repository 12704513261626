import axiosInstance from 'helpers/axiosInstance';

export async function getReferral(params) {
  try {
    const res = await axiosInstance.get(`/referral/config`, {
      params
    });
    return res;
  } catch (err) {
    return err;
  }
}
export async function patchReferralHttp(data, referralId) {
  try {
    const res = referralId
      ? await axiosInstance.patch(`/referral/config`, { ...data })
      : await axiosInstance.post(`/referral/config`, { ...data });
    return res;
  } catch (err) {
    return err;
  }
}
